const TRACKED_DIV_CLASS_NAME = 'tracked-usage'
const DEFAULT_USAGE_TRACKER_CONFIG = {
  disable: false,
  interval: 10000, // 10s
  // Please refer to this document for an event list
  // https://developer.mozilla.org/en-US/docs/Web/Events#event_listing
  eventsToTrack: ['click', 'keydown', 'wheel']
};

const getTrackableRootSelector = (root) => {
  if (root) {
    return `${root} .${TRACKED_DIV_CLASS_NAME}`
  }
  return root;
}

const buildEventHandlerMap = (eventsToTrack, eventCounterMap, notifyToTrackUsageFnc) => {
  const commonEventHandler = (eventName,usageCounterMap, notifyToTrackUsage) => {
    let counterValue = usageCounterMap.get(eventName);
    if (counterValue === undefined) {
      counterValue = 0;
    }
    counterValue += 1;
    usageCounterMap.set(eventName, counterValue);
    notifyToTrackUsage();
  }
  const eventHandlerMap = new Map();
  eventsToTrack.forEach(trackEvent => {
    eventHandlerMap.set(trackEvent, commonEventHandler.bind(this, trackEvent, eventCounterMap, notifyToTrackUsageFnc))
  })
  return eventHandlerMap;
}
const addEventListeners = (rootElement, eventHandlerMap) => {
  if (rootElement && eventHandlerMap instanceof Map) {
    eventHandlerMap.forEach((handler, event) => {
      rootElement.addEventListener(event, handler);
    });
  }
}
/**
 * @param root: original root of the widget
 * @param interval: the interval of time to send tracking request
 * @param eventsToTrack: list of events to track
 * @param logWidgetUsageInfoFnc: the main function to send request to log usage
 * @return {string|*}: a selector for new root
 */
const appendTrackedUsageToRoot = (root, interval, eventsToTrack, logWidgetUsageInfoFnc) => {
  const rootElement = document.querySelector(root);
  if (!interval || !Array.isArray(eventsToTrack) || !rootElement) {
    return root;
  }
  const eventCounterMap = new Map();
  // Main method to track usage
  const trackUsage = () => {
    if (eventCounterMap.size) {
      logWidgetUsageInfoFnc(new Map(eventCounterMap));
      eventCounterMap.clear();
    }
  }
  let intervalId;
  let initialEventOccurred = false;

  // Control the flow of tracking usage
  const notifyToTrackUsage = () => {
    // First event
    if (!intervalId && !initialEventOccurred) {
      trackUsage();
      initialEventOccurred = true;
    } else if (!intervalId) {
      intervalId = setInterval(() => {
        trackUsage();
      }, interval);
    }
  }
  const eventHandlerMap = buildEventHandlerMap.call(this, eventsToTrack, eventCounterMap, notifyToTrackUsage);

  // In case the page is unloaded (navigate to the other page, ...), track usage immediately
  window.addEventListener('beforeunload',() => {
    trackUsage();
  });

  const trackedDiv = document.createElement("div");
  trackedDiv.className = TRACKED_DIV_CLASS_NAME;
  rootElement.appendChild(trackedDiv);
  addEventListeners(trackedDiv, eventHandlerMap);
  return getTrackableRootSelector(root);
}
const UsageTracker = {
  DEFAULT_USAGE_TRACKER_CONFIG,
  appendTrackedUsageToRoot
}

export default UsageTracker;
