import '@babel/polyfill';

import { logWidgetInfo, logWidgetUsageInfo } from '@gravity/g-axios';
import * as NucleoManager from '@gravity/nucleo-manager';

import UsageTracker from './UsageTracker';
import { getWidgetBaseUrl, loadWidgets } from './WidgetLoader';

// define the global var for eslint
/* global STX */

// the list of wrappers created
const wrappers = {};

// The list of expected widget to load
const supportedWidgets = [
  'Product',
  'Section',
  'Catalog',
  'WidgetDoc',
  'SeasonCalendar',
  'SeatMap',
  'TournamentCatalog',
  'TicketShop',
  'ProducerDashboard',
  'AvailabilityCalendar',
  'OneClickPurchase',
  'ProductList',
  'Header',
  'Footer',
  'Payment',
  'AliasCreation',
  'Newsletter',
  'DailySchedule',
];

// add a widget to de definition. The widget is added to the list of widget to initialize
// it adds also the local reducer with the name (defined, adapted or generated)
const add = (widgetDef, wrapper) => {
  // Prevent to load not expected widgets/script
  if (supportedWidgets.indexOf(widgetDef.widget) === -1) {
    return;
  }
  const widget = STX[widgetDef.widget];
  // get a name for the widget. It is mainly used to keep the name for actions and reducers
  let name = widgetDef.name || 'default';
  // if already in use, put an index after
  if (wrapper.nucleos[name]) {
    let index = 0;
    while (wrapper.nucleos[name + index]) {
      index += 1;
    }
    name += index;
  }
  // assign the local reducer with the corresponding name
  // the getReducers should return the combinedReducers
  if (widget.getNucleo) {
    const nucleos = wrapper.nucleos;
    nucleos[name] = widget.getNucleo();
  }
  // ensure the name is the final we choose
  wrapper.widgets.push(Object.assign({}, widgetDef, { name }));

  // assign widget usageTrackerConfig
  if (widget.getUsageTrackerConfig) {
    Object.assign(wrapper, { usageTrackerConfig: widget.getUsageTrackerConfig() });
  } else {
    Object.assign(wrapper, { usageTrackerConfig: UsageTracker.DEFAULT_USAGE_TRACKER_CONFIG });
  }
};
function trackWidgetUsageInfo(eventCounterMap, wrapper, widgetDef, interval) {
  const config = wrapper.store.getState().config;
  if (config) {
    const eventCounterInfo = Array.from(eventCounterMap.entries())
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');
    logWidgetUsageInfo(
      `${widgetDef.widget} [UsageTracking] [Interval: ${interval} ms] [${eventCounterInfo}]`,
      widgetDef.widgetBaseURL,
      config.headers,
    );
  }
}
// When all the widgets have been correctly setup, it is time to start the application
// It will create the application store and pass it to each widget why launching them
// it will generate the widgets added
const renderWidgets = async wrapper => {
  // create a new nucleo with the definition
  const mainNucleo = NucleoManager.getNucleo(wrapper.nucleos, {});
  // and create the corresponding store
  const store = NucleoManager.createNucleoStore(mainNucleo);
  // assign it to the wrapper to get it later if needed
  Object.assign(wrapper, { store });

  // get only once the init functions by nucleo name
  const initFunctions = [];
  wrapper.widgets.forEach(widgetDef => {
    const widget = STX[widgetDef.widget];
    if (widget.getGlobalInit) {
      // get the initialization of global, then check that it was not already initialized, if not, init!
      const globalInit = widget.getGlobalInit(widgetDef);
      Object.keys(globalInit).forEach(async key => {
        if (!wrapper.globalInits[key]) {
          // check not already initialized
          const globalInits = wrapper.globalInits; // useless assignment to avoid eslint error
          globalInits[key] = true; // put the control variable
          initFunctions.push(globalInit[key]); // add the init to the list
        }
      });
    }
  });
  // global initialization should only be done with global parameters
  const initResults = initFunctions.map(async fn =>
    fn(wrapper.globalParams, wrapper.store, mainNucleo),
  );
  // ensure init is completely done
  await Promise.all(initResults);
  // Now render widgets with the main store
  wrapper.widgets.forEach(widgetDef => {
    const widget = STX[widgetDef.widget];
    // log widget info
    const config = wrapper.store.getState().config;
    if (config) {
      logWidgetInfo(widgetDef.widget, widgetDef.widgetBaseURL, config.headers);
    }
    const {
      disable = UsageTracker.DEFAULT_USAGE_TRACKER_CONFIG.disable,
      interval = UsageTracker.DEFAULT_USAGE_TRACKER_CONFIG.interval,
      eventsToTrack = UsageTracker.DEFAULT_USAGE_TRACKER_CONFIG.eventsToTrack,
    } = wrapper.usageTrackerConfig;

    const root = disable
      ? widgetDef.root
      : UsageTracker.appendTrackedUsageToRoot(
          widgetDef.root,
          interval,
          eventsToTrack,
          eventCounterMap => {
            trackWidgetUsageInfo(eventCounterMap, wrapper, widgetDef, interval);
          },
        );

    // Change the root to generate widget
    const trackedWidgetDef = { ...widgetDef, root };
    widget.render(trackedWidgetDef, wrapper.store, wrapper.nucleos[widgetDef.name]);
  });
};

// initialize a wrapper for a widget
const start = def => {
  const { widgets, ...globalParams } = def;
  // support different point of sales
  const wrapperNames = Object.keys(wrappers);
  let name = def.name;
  if (name && wrapperNames.indexOf(name) > 0) {
    // TODO : throw error because the wrapper has already be registered
    return;
  }
  if (!name) {
    name = 'default';
    let index = 0;
    while (wrapperNames.indexOf(name + index) > 0) {
      index += 1;
    }
    name += index;
  }

  // first create the combined Reducer
  wrappers[name] = {
    nucleos: {}, // save the nucleos for the store creation
    globalInits: {}, // keep trace of global initialization
    globalParams, // wrapper definition
    name,
    widgets: [], // the widgets list
  };
  const wrapper = wrappers[name];
  const widgetNames = (widgets || []).map(widgetDef => widgetDef.widget);
  // unique widgetNames and load
  loadWidgets([...new Set(widgetNames)], ([widgetBaseURL]) => {
    (widgets || []).forEach(widget => {
      Object.assign(widget, { widgetBaseURL });
      add(widget, wrapper);
    });
    renderWidgets(wrapper);
  });
};

const Widget = {
  start,
  getWidgetBaseUrl,
  // method to get the wrappers and debug if needed
  getWrappers: () => wrappers,
};

export default Widget;
