import { FrontEndError, logError } from '@gravity/g-axios';
/**
 * step 1!
 * define the url in which we should look for widgets
 */
let srcBaseUrl;
// create a loader for all secutix dependencies
const scriptTags = document.getElementsByTagName('script');
const leni = scriptTags.length;
const buildVersion = process.env.BUILD_VERSION;

// use a loop because of htmlCollections
for (let i = 0; i < leni; i += 1) {
  const scriptTag = scriptTags[i];
  const src = scriptTag.src;
  // take the source only if it
  if (src && src.indexOf('Widgets.js') !== -1) {
    const srcParts = src.split('/');
    srcParts.pop();
    srcBaseUrl = [...srcParts, ''].join('/');
    break;
  }
}

const versionedSrcBaseUrl =
  srcBaseUrl && buildVersion
    ? `${new URL(srcBaseUrl).origin}/${buildVersion}${new URL(srcBaseUrl).pathname}`
    : srcBaseUrl;

const getUrls = src => {
  const initialUrl =
    buildVersion && !srcBaseUrl ? `${buildVersion}/${src}` : `${versionedSrcBaseUrl}${src}`;
  const fallbackUrl = `${srcBaseUrl}${src}`;
  return { initialUrl, fallbackUrl };
};

// define how to load a source
// create a tagName to load the source. Use a promise to inform when to do the callback

const loadSource = (widgetName, initialUrl, fallbackUrl) =>
  new Promise(resolve => {
    let attempts = 0;
    const maxAttempts = 3;
    // get the domElement in which we'll load the sources
    const domEl = document.getElementById('Secutix') || document.body;
    const tryLoadScript = url => {
      const newScriptTag = document.createElement('script');
      newScriptTag.src = url;
      newScriptTag.onload = () => resolve(srcBaseUrl);
      newScriptTag.onreadystatechange = () => resolve(srcBaseUrl);
      newScriptTag.onerror = () => {
        // Check if script already exists
        const existingScript = Array.from(domEl.getElementsByTagName('script')).find(
          script => script.src === url,
        );
        if (existingScript) {
          domEl.removeChild(existingScript);
        }

        attempts += 1;
        if (attempts < maxAttempts) {
          tryLoadScript(url);
        } else if (url === initialUrl && initialUrl !== fallbackUrl) {
          // Try without buildVersion with 3 attempts
          // We ignore the fallbackUrl if it is the same as the initialUrl
          attempts = 0;
          tryLoadScript(fallbackUrl);
        } else {
          logError(FrontEndError.LoadWidGetError, {}, { widgetName });
          resolve();
        }
      };

      domEl.appendChild(newScriptTag);
    };
    tryLoadScript(initialUrl);
  });

let vendorPromise;

/**
 * Step 2!
 * Load the needed widgets widgets
 */

// keep the promise of widget loading
const loadingWidgets = {};

// assign the promise
const loadWidgetSource = widgetName => {
  const source = `${widgetName}.js`;
  const { initialUrl, fallbackUrl } = getUrls(source);
  loadingWidgets[widgetName] = vendorPromise.then(() =>
    loadSource(widgetName, initialUrl, fallbackUrl),
  );
};

export const loadWidgets = (widgetNames, onLoad) => {
  console.log('Build version', buildVersion);
  // get the vendor promises because it must be loaded first
  const { initialUrl, fallbackUrl } = getUrls('vendor.js');
  vendorPromise = vendorPromise || loadSource('vendor', initialUrl, fallbackUrl);
  // get the list of not loaded/loading widgets
  const widgetsToLoad = widgetNames.filter(widgetName => !loadingWidgets[widgetName]);
  // add the promises on the load
  widgetsToLoad.map(widgetName => loadWidgetSource(widgetName));
  // get the promises of loading or loaded files
  const loadingPromises = widgetNames.map(widgetName => loadingWidgets[widgetName]);
  // when all promises have been resolved -> call the onLoad function
  Promise.all(loadingPromises).then(onLoad);
};

export const getWidgetBaseUrl = () => srcBaseUrl;
